import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import {
    PercentageType,
    PixelType,
    ResponsiveType,
    SystemSpacingsProps,
} from '@sport1/news-styleguide/Types'
import {
    adFallbacks,
    fallbackSizes,
    SLOTS_WITH_FALLBACK_ENUM,
} from '@/components/Ads/AdSlot/AdFallbackMap'
import { isPureActive } from '@/components/ThirdParty/Piano/util'
import { AdPlatform, AdPlatformProps, allowedPlacements } from '@/helpers/adHelper'
import useInViewport from '@/hooks/useInViewport'
import Config from '@/utils/Config'
import { useMobile } from '@/hooks/useMobile'

export type DisplayAdProps = SystemSpacingsProps & {
    /**
     * The placement name of the ad, e.g. sky, banner, rectangle
     */
    placement?: string

    /**
     * The placement id of the ad, e.g. banner, banner2, rectangle, rectangle2
     */
    placementId?: string

    /**
     * Adds random number as suffix to the placementId. Used when
     * the amount of ads of that name is not known, eg in galleries
     * for mrecs.
     *
     * The placements event_top and tickerfeed need that in any
     * case, because these are used in the ticker and have an unknown
     * amount of renders.
     * @deprecated
     */
    random?: boolean

    /**
     * avoid setting bottom space to the Ad
     */
    disableBottomSpace?: boolean

    width?: ResponsiveType<PixelType | number | PercentageType>

    scrollHeight?: number

    /**
     * Use this flag when you have potentially endless amount of ads
     * on one page to make sure that every ad will be properly displayed.
     *
     * This flag unregisters the placement that is not in the viewport
     * and registers it back when its back in the viewport again.
     */
    endless?: boolean

    /**
     * the types from the backend; helps to avoid CLS
     * eg BANNER, MREC
     */
    placeholderType?: string

    enabled?: 'mobile' | 'desktop'

    targeting?: Record<string, string[]>

    mobileId?: string

    desktopId?: string
} & AdPlatformProps

const createFallbackAd = (placement: SLOTS_WITH_FALLBACK_ENUM, placementId: string) => {
    const randomAd = adFallbacks[Math.floor(Math.random() * adFallbacks.length)]
    let fallbackSize, fallbackImageUrl
    /**
     * All topomobiles except the first should behave like rectangles,
     * but the first topmobile is dependent on the campaign, so we don't have to worry about it
     */
    if (placement === 'topmobile') {
        fallbackSize = fallbackSizes.rectangle
        fallbackImageUrl = randomAd.rectangle
    } else {
        fallbackSize = fallbackSizes[placement]
        fallbackImageUrl = randomAd[placement]
    }
    const ad = document.createElement('div')
    // temporary solution from stroeer
    ad.id = `backfill-${placementId}`
    ad.style.margin = '0 auto'
    ad.style.width = fallbackSize.width
    ad.style.height = fallbackSize.height
    ad.style.cursor = 'pointer'
    ad.innerHTML = `<img src="${fallbackImageUrl}" alt="${placement} Fallback" />`
    ad.onclick = () => (window.location.href = randomAd.url)

    return ad
}

const DisplayAd: FC<DisplayAdProps> = ({
    disableBottomSpace,
    enabled,
    endless,
    placeholderType,
    placement,
    placementId,
    platform,
    scrollHeight = 0,
    targeting,
    mobileId,
    desktopId,
    /**
     * TODO: Ströer, re-introduce if needed
     */
    // adWithPlaceholder = false,
    // delay = 0,
    // onDelayComplete,
    // random = false,

    ...rest
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const loaded = useRef(false)
    const hasBeenInViewport = useRef(false)
    const isInViewport = useInViewport(ref)
    const { isMobile } = useMobile()

    const initialAdIdsClass = useMemo(() => {
        const mobileClass = mobileId ? `s1-ad-mobile-id-${mobileId} ` : ''
        const desktop = desktopId ? `s1-ad-desktop-id-${desktopId}` : ''
        return mobileClass + desktop
    }, [mobileId, desktopId])

    const shouldRenderIfEnabled = useMemo(() => {
        if (enabled) {
            return (!isMobile && enabled === 'desktop') || (isMobile && enabled === 'mobile')
        }
        return true
    }, [isMobile, enabled])

    const shouldRender = useCallback((): boolean => {
        if (!Config.ADS_ACTIVE || isPureActive()) {
            return false
        }
        if (!placement && !placeholderType) {
            return false
        }
        /**
         * Stroeer
         * We check if placement is allowed on the given platform
         */
        if (
            placement &&
            document.documentElement.classList.contains('s1-device-mobile') &&
            !allowedPlacements.mobile.includes(placement)
        )
            return false
        if (platform === AdPlatform.BOTH || platform === undefined) {
            return true
        }

        return isMobile ? platform === AdPlatform.MOBILE : platform === AdPlatform.WEB
    }, [placement, placeholderType, isMobile, platform])

    const registerAd = useCallback(() => {
        if (
            ref.current &&
            placementId &&
            placement &&
            !loaded.current &&
            (!window.S1RegisteredSlots || !window.S1RegisteredSlots[placementId])
        ) {
            loaded.current = true
            window.SDG?.cmd.push(function () {
                // eslint-disable-next-line no-console
                console.debug(
                    'Ads: SDG.Publisher?.registerSlot?.(placementId, ref.current)?.load?.()',
                    placementId,
                    ref.current
                )
                window.S1RegisteredSlots = window.S1RegisteredSlots || {}
                if (!window.S1RegisteredSlots[placementId]) {
                    window.S1RegisteredSlots[placementId] = true
                    const slot = window.SDG?.Publisher?.registerSlot?.(
                        placementId,
                        ref.current as HTMLDivElement
                    )
                    if (targeting) {
                        slot?.setTargeting?.(targeting)
                    }
                    // The first topmobile, is dependent on the campaign, so there is no fallback
                    if (placement in SLOTS_WITH_FALLBACK_ENUM && placementId !== 'topmobile') {
                        slot?.nativeBackfill?.(() =>
                            createFallbackAd(placement as SLOTS_WITH_FALLBACK_ENUM, placementId)
                        )
                    } else if (placementId === 'topmobile') {
                        slot?.configure({ fixedHeight: 0, reserveSpace: false })
                    }
                    slot?.load?.()
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(
                        'Did not SDG.Publisher.registerSlot, was registered already',
                        placementId
                    )
                }
            })
        }
    }, [placementId, targeting, placement])

    const unregisterAd = useCallback(() => {
        if (ref.current && placementId) {
            window.SDG?.cmd.push(function () {
                // eslint-disable-next-line no-console
                console.debug(
                    'Ads: SDG.Publisher?.unregisterSlot?.(placementId, true)',
                    placementId
                )
                window.SDG?.Publisher?.unregisterSlot?.(placementId, true)
                loaded.current = false
                window.S1RegisteredSlots = window.S1RegisteredSlots || {}
                window.S1RegisteredSlots[placementId] = false
            })
        }
    }, [placementId])

    useEffect(() => {
        if (!shouldRender()) {
            return
        }
        if (!isInViewport && endless && hasBeenInViewport.current) {
            unregisterAd()
        } else if (isInViewport && endless) {
            registerAd()
            hasBeenInViewport.current = true
        }
        if (!loaded.current && !endless) {
            registerAd()
        }
    }, [
        placement,
        placementId,
        platform,
        shouldRender,
        isInViewport,
        endless,
        registerAd,
        unregisterAd,
    ])

    if (!Config.ADS_ACTIVE || !shouldRenderIfEnabled) return null

    return (
        <NonFlexingContainer
            className="s1-ad-container"
            centerHorizontal
            testID={placement}
            {...rest}
        >
            <div
                ref={ref}
                className={`s1-ad ${initialAdIdsClass} s1-ad-name-${placement} s1-ad-${placementId} s1-placeholder-type-${placeholderType} text-center ${
                    !disableBottomSpace ? 'mb-6' : ''
                }`}
                style={{
                    top: scrollHeight ? `${scrollHeight}px` : undefined,
                }}
            >
                <div id={placementId}></div>
            </div>
            <div
                className={`s1-debug-ad s1-debug-ad-name-${placement} s1-debug-ad-${placementId} s1-debug-placeholder-type-${placeholderType}`}
            >
                {JSON.stringify(
                    {
                        endless,
                        placeholderType,
                        placement,
                        placementId,
                        platform,
                        targeting,
                    },
                    null,
                    4
                )}
            </div>
        </NonFlexingContainer>
    )
}

export default DisplayAd
