import { TagSearchInputProps } from '@sport1/types/web'
import React, { FC, useCallback } from 'react'
import DummySearchBar from '@sport1/news-styleguide/DummySearchBar'
import { useOverlay } from '@/utils/overlay/OverlayProvider'

const Search: FC<TagSearchInputProps> = ({ inputText, popularUrl, url }) => {
    const { openOverlay } = useOverlay()
    const onSearchBarClick = useCallback(() => {
        openOverlay({
            type: 'search',
            content: { placeholder: inputText, searchUrl: url, popularUrl },
        })
    }, [inputText, openOverlay, popularUrl, url])
    return (
        <DummySearchBar
            placeholder={inputText}
            onPress={onSearchBarClick}
            testID="dummy-search-bar"
        />
    )
}

export default Search
