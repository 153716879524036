import React, {
    FC,
    memo,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
    useTransition,
} from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Tonal from '@sport1/news-styleguide/Tonal'
import { LabelProps, TagProps } from '@sport1/types/web'
import { spacings } from '@sport1/news-styleguide/spacings'
import BurgerMenuContent from '@/components/BurgerMenu/BurgerMenuContent'
import { useNavigation } from '@/utils/navigation/Standard'
import { useTracking } from '@/utils/tracking/TrackingProvider'

type BurgerMenuProps = {
    isBurgerMenuOpen: boolean
    initialTag?: TagProps | LabelProps
    onStateChange: (isBurgerMenuOpen: boolean) => void
}

export const BurgerMenu: FC<BurgerMenuProps> = ({
    isBurgerMenuOpen,
    initialTag,
    onStateChange,
}): ReactElement => {
    const bodyClassName = useRef<string | undefined>()
    const animatedRef = React.useRef<HTMLDivElement | null>(null)
    const containerRef = useRef<(ReactElement & HTMLDivElement) | null>(null)
    const { navigation } = useNavigation()
    const [isOpen, setIsOpen] = useState(false)
    const { trackIvwInfo, trackIvwView } = useTracking()
    const [, startTransition] = useTransition()

    const scrollToTop = useCallback(() => {
        if (animatedRef.current) {
            animatedRef.current.scrollTop = 0
        }
    }, [])

    const toggleMenu = useCallback(() => {
        if (isOpen) {
            document.body.className = `${bodyClassName.current}`
            startTransition(() => setIsOpen(false))
            onStateChange(false)
            scrollToTop()
        } else {
            bodyClassName.current = document.body.className
            document.body.className = `${bodyClassName.current} overflow-hidden sm:overflow-auto`
            startTransition(() => setIsOpen(true))
            onStateChange(true)
        }
    }, [isOpen, onStateChange, scrollToTop])

    const closeMenu = useCallback(() => {
        toggleMenu()
        trackIvwInfo({
            ivw_context: 'close-menu',
        })
        trackIvwView()
    }, [toggleMenu, trackIvwView, trackIvwInfo])

    useEffect(() => {
        if (isOpen !== isBurgerMenuOpen) {
            toggleMenu()
        }
    }, [isBurgerMenuOpen, isOpen, toggleMenu])

    return (
        <>
            {navigation?.burgerMenuLayout ? (
                <div
                    ref={animatedRef}
                    data-testid="burger-menu-container"
                    className="w-full sm:w-375"
                    style={{
                        bottom: 0,
                        left: 0,
                        marginLeft: isOpen ? spacings['spacing-none'] : '-768px',
                        overflowY: 'auto',
                        position: 'fixed',
                        top: 0,
                        transition: 'margin-left 0.2s ease-out',
                        willChange: 'margin-left',
                        zIndex: 2147483647,
                    }}
                >
                    <NonFlexingContainer
                        innerRef={containerRef}
                        pointerEvents="auto"
                        backgroundColor="pearl"
                        height="100%"
                    >
                        <BurgerMenuContent
                            isOpen={isOpen}
                            initialTag={initialTag}
                            layoutData={navigation.burgerMenuLayout}
                            toggleMenu={toggleMenu}
                            closeMenu={closeMenu}
                            scrollToTop={scrollToTop}
                        />
                    </NonFlexingContainer>
                </div>
            ) : null}
            {isOpen ? (
                <Tonal
                    position="fixed"
                    top={0}
                    left={0}
                    bottom={0}
                    zIndex={2147483646}
                    onPress={closeMenu}
                />
            ) : null}
        </>
    )
}

export default memo(BurgerMenu)
